import {Stack} from "@mui/material";
import MpoRequest from "../components/MpoRequest";

const sx = {
    container: {
        marginTop: "100px",
    }
}

function MpoMain() {
    return (
        <Stack sx={sx.container} spacing={3}>
            <MpoRequest />
        </Stack>
    )
}

export default MpoMain;