import {getTokenCookie, setTokenCookie} from "./cookies";
import {apiRefreshToken} from "./api_user";

const API_BASE_URL= process.env.REACT_APP_API_PREFIX || '/api-dev/v1';
const BEES_LAMBDA_BASE_URL = process.env.REACT_APP_BEES_LAMBDA_BASE_URL ||
    "https://u6ezdznje5.execute-api.ap-northeast-2.amazonaws.com/dev";

class ApiError extends Error {
    constructor(message, cause) {
        super(message);
        this.name = 'ApiError';
        this.cause = cause;
    }
}

async function fetchWithToken(url, options) {
    options.headers['Authorization'] = `Bearer ${getTokenCookie()}`;
    let response = await fetch(url, options);
    if (response.status === 401) {
        console.log('[fetchWithToken] 401 Unauthorized');
        const token = await apiRefreshToken();
        if (token) {
            setTokenCookie(token);
            options.headers['Authorization'] = `Bearer ${token}`;
            response = await fetch(url, options);
            return response;
        } else {
            throw new ApiError('Refresh token failed', {status: 401});
        }
    }
    return response;
}

function build_default_headers() {
    return {
        'Content-Type': 'application/json',
    }
}

async function check_api_response(response) {
    // console.log('[check_api_response]:', response);
    let json;
    try {
        json = await response.json();
    } catch (e) {
        json = null;
    }
    if (! response.ok) {
        const cause = {
            status: response.status,
            err_code: json?.err_code,
            err_info: json?.err_info,
        }
        console.log('[ApiError]', json?.message, response.statusText, cause);
        throw new ApiError(json?.message || response.statusText, cause);
    }
    return json;
}

export {
    API_BASE_URL,
    BEES_LAMBDA_BASE_URL,
    ApiError,
    check_api_response,
    build_default_headers,
    fetchWithToken,
}