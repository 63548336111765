import {BEES_LAMBDA_BASE_URL, build_default_headers, check_api_response} from "./api_common";

export const api_mpoRequestToBeesLambda = async (requester, requestUrl) => {
    console.log('[API] mpo request to bees lambda', requester, requestUrl);
    const reqData = {
        "request_payload": {
            "requester": requester,
            "request_type": "url",
            "url": requestUrl,
            "intend": ""
        },
        "options": {
            "user_notification_via": "slack",
            "monitor_notification_via": "slack",
            "archive_to": "notion"
        }
    }
    const response = await fetch(`${BEES_LAMBDA_BASE_URL}/mpo/request`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(reqData),
    });
    const json = await check_api_response(response);
    return json;
}

